import ApiInstance from "../configs/ApiInstance";

const UploadImages = async (file) => {
    try {
        const response = await ApiInstance.userAwsImageService.post('/upload-articles/images', file);
        return response.data;
    } catch (error) {
        console.error('Image upload failed:', error);
        throw error;
    }
};

export default UploadImages;
