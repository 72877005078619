import configs from "./endpoints.json"
import axios from "axios"

const articleService = axios.create({
    baseURL: `${configs.articleService}`,
    headers: {
        'Content-Type': 'application/json'
    }
})

const userAwsImageService = axios.create({
    baseURL: `${configs.userAwsService}`,
    headers: {
        'Content-Type': 'multipart/form-data'
    }
})

const ApiInstance = {
    articleService,
    userAwsImageService
}

export default ApiInstance;