import { Box, Button, Container, Typography, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { navigateToUrl } from 'single-spa';

function ThankYou() {

  const [author, setAuthor] = useState('');

  const handleGoHome = () => {
    navigateToUrl('/');
  };

  useEffect(() => {
    const storedUser = sessionStorage.getItem('user');
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      const nickname = parsedUser.UserAttributes?.find(attr => attr.Name === 'nickname')?.Value || 'N/A';
      setAuthor(nickname);
      console.log(nickname)
    }
  }, []);

  return (
    <Container
      maxWidth="xl"
      sx={{
        marginTop: 15,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundSize: 'cover',
          textAlign: 'center',
        }}
      >
        <Stack spacing={2} sx={{ width: '100%', textAlign: 'left' }}>
          <Typography variant="h4" fontFamily={'serif'}  sx={{ pl: 20 }}>
            Dear {author},
          </Typography>
          <Typography variant="h2" fontFamily={'serif'} textAlign="center">
            Thank You for Your Submission!
          </Typography>
          <Typography variant="h6" fontFamily={'serif'} textAlign="center">
            We are excited to review your work. Your submission has been successfully received.<br/>Our editorial team will contact you within the next three days.
          </Typography>
          <Typography variant="h5" fontFamily={'serif'}  sx={{ pl: 120 }}>
          Best regards Ceylon Diaries Team
          </Typography>
        </Stack>

        <Button
          variant="contained"
          onClick={handleGoHome}
          sx={{ mt: 3, 
            background: 'black',
            "&:hover": {
                background: 'black',
            },
          }}
        >
          Home
        </Button>
      </Box>
    </Container>
  );
}

export default ThankYou