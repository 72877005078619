import React, { useEffect, useState, useRef } from 'react';
import { Alert, Box, Button, Container, Grid, IconButton, Snackbar, TextField, Typography, LinearProgress, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import ImageIcon from '@mui/icons-material/Image';
import CreateArticle from '../services/CreateArticle';
import { navigateToUrl } from "single-spa";
import UploadImages from '../services/UploadImages';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../styles/styles.css'

function WriteArticle() {
    const charLimit = 10000;
    const titleCharLimit = 75;
    const [content, setContent] = useState('');
    const [title, setTitle] = useState('');
    const [file, setFile] = useState(null);
    const [author, setAuthor] = useState('');
    const fileInputRef = useRef(null);
    const [fileName, setFileName] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [category, setCategory] = useState('');
    const [locationUrl, setLocationUrl] = useState('');
    const [subcategory, setSubcategory] = useState('');


    const generateRandomId = () => {
        return Math.floor(Math.random() * 1000000);
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            setFileName(selectedFile.name);
        }
    };


    const handleButtonClick = () => {
        if (!isLoading) {
            fileInputRef.current.click();
        }
    };

    const handleTitleChange = (event) => {
        const newTitle = event.target.value;
        if (newTitle.length <= titleCharLimit) {
            setTitle(newTitle);
        }
    };

    const stripHtmlTags = (html) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    };

    const handleContentChange = (value) => {
        const strippedContent = stripHtmlTags(value);
        if (strippedContent.length <= charLimit) {
            setContent(value);
        }
    };

    const handleCategoryChange = (event) => {
        setCategory(event.target.value);
        setSubcategory('');
    };

    const handleSubcategoryChange = (event) => {
        setSubcategory(event.target.value);
    };



    useEffect(() => {
        const storedUser = sessionStorage.getItem('user');
        if (storedUser) {
            const parsedUser = JSON.parse(storedUser);
            const nickname = parsedUser.UserAttributes?.find(attr => attr.Name === 'nickname')?.Value || 'N/A';
            setAuthor(nickname);
        }
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!file || !title.trim() || !content.trim()) {
            setOpenSnackbar(true);
            return;
        }

        const formData = new FormData(event.currentTarget);
        formData.append('articleImage', file);
        formData.append('title', title);
        formData.append('category', category);
        formData.append('content', content);
        formData.append('author', author);
        formData.append('subcategory', subcategory);
        formData.append('locationUrl', locationUrl ? locationUrl : "");

        setIsLoading(true);

        try {
            const imageUploadResponse = await UploadImages(formData);
            const randomId = generateRandomId();
            await CreateArticle(title, category, content, author, new Date().toISOString(), imageUploadResponse, locationUrl, subcategory ,randomId);
        } catch (error) {
            console.error('Failed to create article', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    const tagOptions = [
        "Entertainment",
        "Sports",
        "Fashion",
        "Travel",
        "Food",
        "Technology",
        "Health",
        "Lifestyle",
        "Business",
        "Education",
        "Environment",
        "Culture",
        "History",
        "Pets",
        "Books",
        "Gaming"
    ];


    const subcategoryOptions = {
        TRAVEL: [
            "Adventure", "Historical", "Nature", "Urban", "Cultural", "Luxury", "Backpacking", "Road Trips",
            "Beaches", "Mountains", "Wildlife", "Eco-Tourism", "Solo Travel", "Family Travel", "Hiking",
            "National Parks", "City Guides", "Travel Tips", "Budget Travel", "Travel Photography", "Festivals"
        ],
        FOOD: [
            "Recipes", "Restaurants", "Diet", "Street Food", "Baking", "Vegan", "Healthy Eating", "International Cuisine",
            "Food Trends", "Desserts", "Quick Meals", "Drinks & Cocktails", "Grilling & BBQ", "Comfort Food",
            "Gluten-Free", "Food Reviews", "Gourmet", "Keto Diet", "Meal Prep", "Farm-to-Table"
        ],
        ENTERTAINMENT: [
            "Movies", "Music", "TV Shows", "Celebrities", "Gaming", "Theater", "Books", "Festivals", "Awards Shows",
            "Concerts", "Stand-up Comedy", "Streaming Services", "Indie Films", "Pop Culture", "Anime & Manga",
            "Video Games", "Podcasts", "Broadway", "Dance", "Art Exhibits", "Fandoms"
        ],
        TECHNOLOGY: [
            "Gadgets", "Software", "AI", "Startups", "Blockchain", "Wearables", "Tech News", "Cybersecurity",
            "Smartphones", "Robotics", "Cloud Computing", "VR/AR", "Programming", "Apps", "Drones", "5G Technology",
            "Gaming Tech", "Electric Vehicles", "Internet of Things (IoT)", "Tech Reviews", "Smart Home Devices"
        ],
        HEALTH: [
            "Fitness", "Wellness", "Nutrition", "Mental Health", "Yoga", "Meditation", "Diseases", "Healthcare",
            "Workouts", "Sleep", "Diet Plans", "Supplements", "Alternative Medicine", "Healthy Aging", "Weight Loss",
            "Self-Care", "Holistic Health", "Stress Management", "Mental Wellness", "Health Tech", "Rehabilitation"
        ],
        LIFESTYLE: [
            "Fashion", "Home Decor", "Beauty", "Travel", "Hobbies", "Personal Development", "Relationships",
            "Work-Life Balance", "Minimalism", "Sustainability", "Fitness", "Social Media", "DIY Projects",
            "Crafting", "Family & Parenting", "Wedding Planning", "Pet Care", "Luxury Lifestyle", "Personal Finance",
            "Mindfulness", "Home Improvement"
        ],
        BUSINESS: [
            "Entrepreneurship", "Finance", "Startups", "Marketing", "E-commerce", "Leadership", "Investing", "Economy",
            "Management", "Small Business", "Corporate Culture", "Business Strategy", "Real Estate", "Cryptocurrency",
            "Stock Market", "Business News", "HR & Talent", "Sales", "Supply Chain", "Innovation", "Mergers & Acquisitions"
        ],
        SPORTS: [
            "Football", "Basketball", "Cricket", "Olympics", "Tennis", "Golf", "Rugby", "Motorsports",
            "Track & Field", "Swimming", "Boxing", "Martial Arts", "Baseball", "Hockey", "Cycling", "Winter Sports",
            "Extreme Sports", "Esports", "Fitness Competitions", "Gymnastics", "Rowing"
        ],
        ENVIRONMENT: [
            "Climate Change", "Sustainability", "Wildlife", "Conservation", "Pollution", "Renewable Energy",
            "Forests", "Water Resources", "Endangered Species", "Global Warming", "Green Technology", "Ocean Health",
            "Environmental Policy", "Zero Waste", "Recycling", "Sustainable Fashion", "Biodiversity", "Carbon Footprint",
            "Air Quality", "Ecology", "Environmental Activism"
        ],
        EDUCATION: [
            "Online Learning", "Schools", "Colleges", "Exams", "Scholarships", "EdTech", "Study Tips", "Courses",
            "Higher Education", "Vocational Training", "Language Learning", "Distance Learning", "Student Life",
            "Tutoring", "STEM Education", "Educational Apps", "Special Education", "Research", "Early Childhood Education",
            "Certifications", "Learning Disabilities"
        ],
        FASHION: [
            "Trends", "Street Style", "Hauls", "Fashion Week", "Sustainable Fashion", "Accessories", "Fashion Design",
            "Personal Style", "Celebrity Fashion", "Fashion History", "Shopping Tips", "Dresses", "Seasonal Fashion",
            "Plus Size Fashion", "Fashion Photography", "Beauty Trends", "Fashion Illustration", "Wardrobe Essentials",
            "Styling Tips", "Fashion Brands"
        ],
        CULTURE: [
            "Cultural Heritage", "Traditions", "Festivals", "Language", "Food Culture", "Art", "Music", "Folklore",
            "Cultural Studies", "Diversity", "Anthropology", "Cultural Events", "Cultural Exchange", "Identity",
            "Cultural Institutions", "Global Cultures", "Cultural Impact", "Art Movements", "Cultural Criticism",
            "Performing Arts", "Visual Arts"
        ],
        HISTORY: [
            "Ancient Civilizations", "Modern History", "World Wars", "Historical Figures", "Cultural History",
            "Economic History", "Military History", "History of Technology", "Social Movements", "Historical Events",
            "History of Art", "Political History", "Intellectual History", "Women's History", "History of Science",
            "Local History", "Archaeology", "History of Medicine", "Environmental History", "History of Education"
        ],
        PETS: [
            "Dog Training", "Cat Care", "Pet Nutrition", "Pet Grooming", "Behavior Issues", "Pet Adoption",
            "Exotic Pets", "Pet Health", "Pet Products", "Traveling with Pets", "Pet Safety", "Pet Insurance",
            "Training Techniques", "Pet-Friendly Activities", "Animal Welfare", "Pet Breeds", "Veterinary Care",
            "Senior Pets", "Pet Psychology", "Pet Fun Facts"
        ],
        GAMING: [
            "Game Reviews", "Game Design", "Esports", "Streaming", "Game Development", "Mobile Gaming",
            "Console Gaming", "PC Gaming", "Indie Games", "Gaming Culture", "Gaming News", "Game Mechanics",
            "Game Engines", "Virtual Reality", "Augmented Reality", "Gaming Communities", "Gaming Events",
            "Gaming Merchandise", "Gaming History", "Game Marketing", "Gamer Lifestyle"
        ],
        BOOKS: [
            "Fiction", "Non-Fiction", "Biographies", "Self-Help", "Fantasy", "Science Fiction", "Mystery",
            "Thriller", "Romance", "Historical Fiction", "Young Adult", "Children's Books", "Graphic Novels",
            "Poetry", "Classic Literature", "Cookbooks", "Travel Literature", "Business Books", "Motivational",
            "Literary Fiction", "Audiobooks"
        ]
    };



    return (
        <Container maxWidth="lg" sx={{ mt: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', minHeight: '100vh' }}>
            <Box width="100%" maxWidth="1000px" p={4} sx={{ border: '1px solid', borderColor: 'black', borderImageSlice: 1, borderRadius: "15px", mb: 10, position: 'relative' }}>
                <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={handleSubmit}>
                    <Grid container spacing={5}>

                    <Grid item xs={12}>
                            <Typography variant='h5' sx={{ marginBottom: 1, letterSpacing: '0.1rem', }} fontWeight={600}>Category</Typography>
                            <FormControl fullWidth required>
                                <Select
                                    labelId="category-label"
                                    id="category"
                                    name="category"
                                    autoComplete="category"
                                    onChange={handleCategoryChange}
                                    className="gradient-select"
                                >
                                    {tagOptions.map((tag, index) => (
                                        <MenuItem key={index} value={tag.toUpperCase()}>
                                            {tag.toUpperCase()}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        {category && subcategoryOptions[category] && (
                            <Grid item xs={12}>
                                <Typography variant='h5' sx={{ marginBottom: 1, letterSpacing: '0.1rem', }} fontWeight={600}>Subcategory</Typography>
                                <FormControl fullWidth required>
                                    <Select
                                        labelId="subcategory-label"
                                        id="subcategory"
                                        name="subcategory"
                                        autoComplete="subcategory"
                                        value={subcategory}
                                        onChange={handleSubcategoryChange}
                                        className="gradient-select"
                                    >
                                        {subcategoryOptions[category].map((sub, index) => (
                                            <MenuItem key={index} value={sub.toUpperCase()}>
                                                {sub.toUpperCase()}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        )}

                        {category === 'TRAVEL' && (
                            <Grid item xs={12}>
                                <Typography variant='h5' sx={{ marginBottom: 1, letterSpacing: '0.1rem', }} fontWeight={600}>Location URL (optional)</Typography>
                                <TextField
                                    className="gradient-textfield"
                                    fullWidth
                                    variant='outlined'
                                    value={locationUrl}
                                    onChange={(e) => setLocationUrl(e.target.value)}
                                    placeholder="Enter location URL"
                                    autoComplete="location-url"
                                    sx={{ mb: 2 }}
                                    disabled={isLoading}
                                />
                            </Grid>
                        )}
                        
                        <Grid item xs={12}>
                            <Typography variant='h5' sx={{ marginBottom: 1, letterSpacing: '0.1rem', }} fontWeight={600}>Title</Typography>
                            <TextField
                                className="gradient-textfield"
                                required
                                fullWidth
                                variant='outlined'
                                multiline
                                rows={2}
                                value={title}
                                onChange={handleTitleChange}
                                autoComplete="title"
                                name="title"
                                id="title"
                                autoFocus
                                sx={{ mb: 2 }}
                                InputProps={{
                                    sx: {
                                        fontSize: '1.1rem',
                                    },
                                }}
                                disabled={isLoading}
                            />
                            <Typography variant='caption' display='block' gutterBottom>
                                {title.length}/{titleCharLimit} characters
                            </Typography>
                        </Grid>

                     

                        <Grid item xs={12}>
                            <Box>
                                <Typography variant='h5' sx={{ marginBottom: 1, letterSpacing: '0.1rem', }} fontWeight={600}>Content</Typography>
                                <Box sx={{ overflow: 'hidden', border: '1px solid',  borderColor:'black' , borderImageSlice: 1, position: 'relative', boxSizing: 'border-box' }}>
                                    <ReactQuill
                                        value={content}
                                        className="gradient-textfield"
                                        onChange={handleContentChange}
                                        modules={{
                                            toolbar: [
                                                [{ size: [] }],
                                                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                                { 'indent': '-1' }, { 'indent': '+1' }],
                                                ['link'],
                                            ],
                                        }}
                                        formats={[
                                            'header', 'font', 'size',
                                            'bold', 'italic', 'underline', 'strike', 'blockquote',
                                            'list', 'bullet', 'indent',
                                            'link',
                                        ]}
                                        style={{ height: '700px' }}
                                        readOnly={isLoading}
                                    />
                                </Box>
                                <Typography variant='caption' display='block' sx={{ mt: 2 }}>
                                    {stripHtmlTags(content).length}/{charLimit} characters
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                                <Typography variant='h6' sx={{ marginBottom: 1, marginTop: 2, flexShrink: 0 }} fontWeight={600}>
                                    Upload Image
                                </Typography>
                                <IconButton
                                    onClick={handleButtonClick}
                                    sx={{
                                        background: 'black',
                                        "&:hover": {
                                            background: 'black',
                                        },
                                        color: 'white',
                                        borderRadius: '25px',
                                        width: '40px',
                                        height: '40px',
                                        justifyContent: 'center',
                                        mx: 1,
                                        mt: 1
                                    }}
                                    disabled={isLoading}
                                >
                                    <ImageIcon />
                                </IconButton>
                                <input
                                    type="file"
                                    id="profileImage"
                                    onChange={handleFileChange}
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                />
                                {fileName && (
                                    <Typography variant="body2" sx={{ mt: 1 }}>
                                        Selected file: {fileName}
                                    </Typography>
                                )}
                            </Box>
                            <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1, mb: 8 }}>
                                Instructions: Click the upload button to select an image file. Ensure the file is in JPG, PNG format and does not exceed 5MB.<br />
                                Image minimum resolution should be 2048 x 1152 pixels.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Box sx={{ position: 'absolute', bottom: 16, right: 16 }}>
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{
                                fontWeight: 550,
                                letterSpacing: 2,
                                background: 'black',
                                "&:hover": {
                                    background: 'black',
                                },
                                mb: 2
                            }}
                            disabled={isLoading}
                        >
                            Submit to Review
                        </Button>
                        {isLoading && (
                            <Box sx={{ width: '100%' }}>
                                <LinearProgress color="inherit" />
                            </Box>
                        )}
                    </Box>
                </Box>

                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert onClose={handleSnackbarClose} severity="warning" sx={{ width: '1000px' }}>
                        Please fill in the title, content and upload image before submitting.
                    </Alert>
                </Snackbar>
            </Box>
        </Container>
    );
}

export default WriteArticle;
