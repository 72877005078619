import ApiInstance from "../configs/ApiInstance";
import { navigateToUrl } from "single-spa";

async function CreateArticle(title,category, content, author, updatedAt, imageUploadResponse, locationUrl,subcategory, randomId) {
    try {
        const articleImageKey = imageUploadResponse.articleImageKey;
        const status = "PENDING"
        const response = await ApiInstance.articleService.post("/", {
            title,
            category,
            content,
            author,
            articleImageKey,
            status,
            updatedAt,
            locationUrl,
            subcategory
        });
        navigateToUrl(`/writearticle/confirm/${randomId}`);
        return response.data;
    } catch (error) {
        console.log("Error occurred when creating new article", error?.message);
        throw error;
    }
}

export default CreateArticle;