import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import WriteArticle from './components/WriteArticle';
import ThankYou from './components/ThankYou';
import { Divider, Typography } from '@mui/material';

function Screen() {
    return (
        <div>
            <Divider textAlign="right" sx={{ mt: 5 }}>
                <Typography variant="h2" sx={{
                    fontFamily: "serif",
                    letterSpacing: "0.2rem",
                    textAlign: 'left',
                    fontSize: { xs: 'h5.fontSize', sm: 'h3.fontSize', md: 'h2.fontSize' }
                }}>
                    Write an Article
                </Typography>
            </Divider>
            
            <Router>
                <Routes>
                    <Route path="/writearticle" element={<WriteArticle />} />
                    <Route path="/writearticle/confirm/:randomId" element={<ThankYou />} /> {/* Updated route */}
                </Routes>
            </Router>
        </div>
    );
}

export default Screen;
